<script setup lang="ts">
import { Empty } from 'ant-design-vue'

import { ExpandOutlined } from '@ant-design/icons-vue'
import { isNumber } from 'lodash-es'
import type { CustomMenuData, CustomMenuDataItem } from '../components/menu-context'
import RootComponent from '../components/root-component.vue'
import { translateWallListToMenuChildren } from '../components/tools'
import Video from '../components/video.vue'
import { getVideoDeviceByGroup } from '@/api/video'
import { getTvWallApi, queryPublicTvWallsApi } from '@/api/tv-wall'
import { onFullScreen } from '@/utils/screen'

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE

const selectedKeys = ref([])
const openKeys = ref([])
const menuData = ref<CustomMenuData>([])
const wallVideos = ref<Record<number, TvVideo[]>>()
const containerMax = ref(1)

async function onSelectedKeys(val: CustomMenuDataItem[]) {
  selectedKeys.value = val.map(item => item.id) as any

  wallVideos.value = undefined
  containerMax.value = 1

  if (val[0].type === 'wall') {
    if (isNumber(val[0].value)) {
      await getWallDetail(val[0].value as number)
    }
  }
  else if (val[0].type === 'device') {
    containerMax.value = 1
    wallVideos.value = { 1: [{ deviceCode: val[0].value, deviceName: val[0].title }] }
  }
}

function onOpenKeys(val: CustomMenuDataItem[]) {
  openKeys.value = val.map(item => (item.id)) as any
}

async function getWallDetail(wallId: number) {
  const { videos, containerMax: max } = await getTvWallApi(wallId)

  const videoMap = {} as any
  videos.forEach((video) => {
    if (videoMap[video.containerIndex]) {
      videoMap[video.containerIndex].push(video)
    }
    else {
      videoMap[video.containerIndex] = [video]
    }
  })

  containerMax.value = max
  wallVideos.value = videoMap
}

onMounted(refreshMenu)

async function refreshMenu() {
  const menuDataTemp = []
  const publicWall = await queryPublicTvWallsApi()
  menuDataTemp.push({
    id: 'public-wall',
    value: 'public-wall',
    title: '电视墙',
    type: 'wall',
    children: translateWallListToMenuChildren(publicWall, 'public-wall'),
  } as CustomMenuDataItem)
  // const privateWall = await queryPrivateTvWallsApi()
  // menuDataTemp.push({
  //   id: 'private-wall',
  //   value: 'private-wall',
  //   title: '私有电视墙',
  //   type: 'wall',
  //   children: translateWallListToMenuChildren(privateWall, 'private-wall'),
  // } as CustomMenuDataItem)

  const videoDeviceByGroupTree = await getVideoDeviceByGroup()

  menuDataTemp.push({
    id: 'video-device-by-group',
    value: 'video-device-by-group',
    title: '视频设备',
    type: 'device',
    children: videoDeviceByGroupTree.map(item => convertTree(item)),
  })

  menuData.value = menuDataTemp
}

function convertTree(node: any) {
  if (!node)
    return null

  // TODO
  const newNode = {
    id: `device-${node.id}`,
    value: node.id,
    title: node.label,
  } as any

  if (!node.children || node.children.length === 0) {
    newNode.children = node.node.devices.map((device: any) => {
      return {
        id: `device-${device.deviceCode}`,
        value: device.deviceCode,
        title: device.deviceName,
        type: 'device',
        children: [],
      }
    })
  }

  if (node.children && node.children.length > 0)
    newNode.children = node.children.map(convertTree)

  return newNode
}
</script>

<template>
  <page-container>
    <content-header title="实时视频">
      <template #right>
        <a-tooltip placement="left" :arrow="true">
          <template #title>
            点击切换全屏
          </template>
          <ExpandOutlined @click="onFullScreen('#tv-wall-id')" />
        </a-tooltip>
      </template>
    </content-header>
    <flex-content :need-padding="false">
      <RootComponent
        :menu-data="menuData"
        :open-keys="openKeys"
        :selected-keys="selectedKeys"
        @update:selected-keys="onSelectedKeys"
        @update:open-keys="onOpenKeys"
      >
        <GridScreen :container-max="containerMax" :screen-full="true" pos-relative>
          <template #default="{ index }">
            <div v-if="wallVideos && wallVideos[index] && wallVideos[index].length > 0" w-full h-full>
              <Video :videos="wallVideos[index]" />
            </div>
            <div v-else h-full w-full flex justify-center items-center>
              <a-empty :image="simpleImage" description="无视频" />
            </div>
          </template>
        </GridScreen>
      </RootComponent>
    </flex-content>
  </page-container>
</template>

<style lang="scss" scoped></style>
