import type { CustomMenuDataItem } from './menu-context'

export function translateWallListToMenuChildren(wallList: TvWall[], prefix: string) {
  return wallList.map((wall) => {
    const { id, userId, containerMax, wallName } = wall
    return {
      id: `${prefix}-${id}`,
      value: wall.id,
      title: wallName,
      containerMax,
      type: 'wall',
      userId,
    } as CustomMenuDataItem
  })
}
