<script setup lang="ts">
import { getVideoByDeviceCodeApi, getVideoDeviceLiveApi } from '@/api/video'
import { getDeviceVideoUrl } from '@/utils/video'

const props = defineProps<{ videos: TvVideo[] }>()

const currentVideo = ref({ videoUrl: '', seekable: false } as TvVideo)

const timer = ref()

watch(() => props.videos, async () => {
  clearTimeout(timer.value)
  await getChannelNamesSequentially()
}, { immediate: true })

async function assignVideoProperty(video: TvVideo) {
  /** 获取视频信息 */
  const getVideoMessage = async (channelName: string, deviceCode: string) => {
    const { seekable, url, proxied } = await getVideoDeviceLiveApi(channelName, deviceCode)
    const videoUrl = getDeviceVideoUrl(url ?? '', proxied ?? false) as string

    return { seekable, videoUrl }
  }

  let { channelName, deviceCode } = video

  if (!channelName && deviceCode) {
    const videoDevice = await getVideoByDeviceCodeApi(deviceCode)
    channelName = videoDevice.channels.length > 0 ? videoDevice.channels[0].name : ''
  }

  let seekable = false
  let videoUrl = ''
  if (channelName && deviceCode) {
    const videoMessage = await getVideoMessage(channelName, deviceCode)
    seekable = videoMessage.seekable as boolean
    videoUrl = videoMessage.videoUrl
  }

  video.videoUrl = videoUrl
  video.seekable = seekable
}

async function getChannelNamesSequentially() {
  let currentIndex = 0 // 当前要返回的视频索引
  const videoCount = props.videos.length // 视频总数

  // 返回下一个channelName的函数
  async function getNextChannelName() {
    if (timer.value)
      clearTimeout(timer.value)

    if (currentIndex >= videoCount) {
      // 如果所有视频都已处理完，重置索引
      currentIndex = 0
    }

    // 返回当前视频的channelName
    const video = props.videos[currentIndex]
    await assignVideoProperty(video)
    // 给当前切换到的video添加videoUrl和seekable字段
    currentVideo.value = video

    // 更新索引为下一个要返回的视频
    currentIndex = (currentIndex + 1) % videoCount

    // 设置定时器，在指定时间后再次调用getNextChannelName函数
    if (video.secondsToNext) {
      const timeout = video.secondsToNext * 1000
      timer.value = setTimeout(getNextChannelName, timeout)
    }
  }

  await getNextChannelName()
}
</script>

<template>
  <video-player
    v-if="currentVideo.videoUrl"
    :has-audio="false"
    :visible="true"
    :seekable="currentVideo.seekable"
    :video-url="currentVideo.videoUrl"
  />
</template>
